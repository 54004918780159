// src/redux/slices/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the type for the user state
interface UserState {
  user: { [key: string]: any } | null;  // You can replace 'any' with a more specific user type
  isLoading: boolean;
}

// Define the initial state using the UserState type
const initialState: UserState = {
  user: null,
  isLoading: false,
};

// Create the slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Define setUser action with a typed payload
    setUser: (state, action: PayloadAction<{ user: any; isLoading: boolean }>) => {
      state.user = action.payload.user;
      state.isLoading = action.payload.isLoading;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
