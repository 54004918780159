import React, { useEffect, useState } from 'react';
import { Link, Box,} from '@mui/material';

export const footerLinks = [
  
    <Box mt={2} display="flex" justifyContent="center">
    <Link href="https://www.rounda.com.au/user-faqs.html" variant="body2" sx={{ mr: 3}}>
      Help
    </Link>
    <Link href="https://www.rounda.com.au/user-terms-and-conditions-how-user-funds-are-distributed.html" variant="body2" >
      Terms
    </Link>
    <Link href="https://www.rounda.com.au/user-faqs.html" variant="body2" sx={{ ml: 3}}>
      Privacy
    </Link>
  </Box>
];
