import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel, CircularProgress } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks.jsx';
import { theme, style, config, StyledRadio } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps.jsx';
import { api } from '../../../config/apis.jsx';
import { redirects, parent_clubs } from '../../../config/redirects.jsx';
import { getTheme } from '../../../config/theme';



  const ConsentedVerified = () => {
    const [subdomain, setSubdomain] = useState('');
    const [isLoadingTheme, setIsLoadingTheme] = useState(false);
    const [theme, setTheme] = useState({});
    const [assetsPath, setAssetsPath] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRetry, setIsLoadingRetry] = useState(false);
    const navigate = useNavigate();
    const [statusMessage, setStatusMessage] = useState('Checking your bank connection and authorising accounts');
    const [pageTitle, setPageTitle] = useState('Connecting...');
    const location = useLocation();
    const cdrid = localStorage.getItem('cdrid');
    const [stepperStep, setStepperStep] = useState(1);
    const [reconsentRequired, setReconsentRequired] = useState(false);
    const [logOut, setLogOut] = useState(false);
    const [newConsentLink, setNewConsentLink] = useState('');
    const [parentClub, setParentClub] = useState('');
    
{/* Host name identification and configassets loading */}
useEffect(() => {
  const executeAsyncFunctions = async () => {
    setIsLoadingTheme(true);

    // Step 1: Load configuration and assets
    let subdomain;
    const loadConfigAndAssets = async () => {
      const hostname = window.location.hostname;
      const parts = hostname.split('.');
      if (parts.length > 2 && parts[1] === 'sipora' && parts[2] === 'io') {
        subdomain = parts[0];
        setSubdomain(subdomain);
      } else {
        subdomain = 'default';
      }
      localStorage.setItem('host', subdomain);

      const settings = await import(`../../../config/${subdomain}/settings.jsx`);
      setTheme(settings.theme);  // Assuming default export
      setAssetsPath(settings.logoURL);  // Dynamic asset folder
      console.log(settings);

      setIsLoadingTheme(false);
    };

    // Step 2: Retrieve the user record
    const getUserRecord = async (userId) => {
      if (!userId) {
        navigate('/');
      }
      try {
        const response = await axios.post(
          api.getUser,
          { userId: userId },
          { headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant } }
        );
        const cdridA = response.data.userRecord.document._id;
        localStorage.setItem('cdrid', cdridA);

        const clubId = response.data.userRecord.document.supportingClubId;
        console.log("club Id Is:", clubId);

        // Step 3: Find club and check if there's a parent club
        const clubResponse = await axios.post(
          api.findClub,
          { clubId: clubId },
          { headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant } }
        );
        
        if (clubResponse.data.document.parent_club) {
          setParentClub(clubResponse.data.document.parent_club);
        }

        // Step 4: Handle redirects based on clubId and parentClub
        const parentClub = clubResponse.data.document.parent_club;
        console.log("parent Club Is", parentClub)
        // LifeChanger Redirect
        if (clubId?.toString() === '6503a0a55e3b99bdc8236198' || parentClub?.toString() === '6503a0a55e3b99bdc8236198') {
          window.location.href = `${redirects.lifechanger}${userId}`;
          return;
        }
        // ACA Redirect
        if (clubId?.toString() === '66e2422d6298959464dd950d' || parentClub?.toString() === '66e2422d6298959464dd950d') {
          window.location.href = `${redirects.aca}${userId}`;
          return;
        }
        // CMI Redirect
        if (clubId?.toString() === '67047782ebcc0a0f23f30186' || parentClub?.toString() === '67047782ebcc0a0f23f30186') {
          window.location.href = `${redirects.cmi}${userId}`;
          return;
        }
        // Family of League Redirect
        if (parentClub?.toString() === '66e243e42cf08d534afd54cc') {
          window.location.href = `${redirects.familyofleague}${userId}`;
          return;
        }

      } catch (error) {
        console.error('Error fetching user record:', error);
      }
    };

    // Step 5: Find Job IDs and process jobs
    const findJobIds = async (cdrid) => {
      try {
        const response = await axios.post(
          api.findJobs,
          { cdrid: cdrid },
          { headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant } }
        );
        const jobId = response.data.jobId;
        localStorage.setItem('jobId', jobId);

        const status = response.status;

        if (status === 200) {
          setStepperStep(2);
          setPageTitle('Confirming Connection');
          setStatusMessage('FOUND! Finalising connection with your bank');
          await fetchJob(jobId);
        }
      } catch (error) {
        setPageTitle('Verification');
        setStatusMessage('For security reasons we need you to verify your RoundA account by logging in.');
      }
    };

    // Step 6: Fetch Job Information
    const fetchJob = async (jobId, retries = 0) => {
      const maxRetries = 5;
      const cdrid = localStorage.getItem('cdrid');
      try {
        const response = await axios.post(
          api.checkJob,
          { jobId: jobId, cdrid: cdrid },
          { headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant } }
        );

        const status = response.status;
        if (status === 200) {
          setStepperStep(2);
          setPageTitle('Finding Accounts!');
          setStatusMessage('Successfully connected - we are now retrieving your accounts.');
          const retrieveAccounts = await axios.post(
            api.retrieveAccounts,
            { cdrid: cdrid },
            { headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant } }
          );
          if (retrieveAccounts.status === 200) {
            setPageTitle('Success!');
            setStatusMessage('Accounts have successfully been retrieved.');
            navigate('/roundup-accounts');
          }
        } else if (status === 202) {
          setPageTitle('Checking!');
          setStatusMessage('Receiving communications from your bank. This might take a little while.');
          if (retries < maxRetries) {
            setTimeout(() => fetchJob(jobId, retries + 1), 5000);
          } else {
            setPageTitle('Verification');
            setStatusMessage('For security reasons we need you to verify your RoundA account by logging in.');
          }
        }
      } catch (error) {
        setPageTitle('Verification');
        setStatusMessage('For security reasons we need you to verify your RoundA account by logging in.');
      }
    };

    // Execute the chain of functions
    try {
      const userId = localStorage.getItem('userId');
      await loadConfigAndAssets();
      await getUserRecord(userId);
      setPageTitle('Searching For Connection!');
      const cdrid = localStorage.getItem('cdrid');
      await findJobIds(cdrid);
    } catch (error) {
      console.error('Error executing async functions:', error);
    }
  };

  executeAsyncFunctions();
}, [navigate]);  // Added navigate as dependency



  const handleRetry = async () => {
    setIsLoadingRetry(true);
    try {
      const response = await axios.post(api.consent,
      {cdrid: cdrid},
      { headers: { 'Content-Type': 'application/json',
        apikey: config.apikey,
            tenant: config.tenant
       } }); // Replace with your actual API endpoint
      const consentLink = response.data.consentLink;
      window.location.href = consentLink // Adjust based on your API response structure
    } catch (error) {
      console.error('Error creating consent Link:', error);
    } finally {
      setIsLoadingRetry(false)
    }
  };

  const logOutButton = async () => {
    navigate('/logout');
  };



  return (
    <ThemeProvider theme={getTheme}>
    <Container component="main" maxWidth="xs">
    {isLoadingTheme ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
 
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
    
         <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={stepperStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
      <Typography variant="h4" component="h2" align="center" mt={2} sx={{fontWeight: 'bold'}} gutterBottom>
      {pageTitle}
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={16} style={{ width: '100%'}}>
      <Typography variant="body2" component="h2" align="center"  gutterBottom>
       {statusMessage}
      </Typography>
      {reconsentRequired && (
                <Button
                onClick={handleRetry}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
                disabled={isLoadingRetry}
    
                      
              >
                {isLoadingRetry ? 'Loading...' : 'Retry'}
             
              </Button>
              )}
        {logOut && (
                <Button
                onClick={logOutButton}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
              
    
                      
              >
              Log In
             
              </Button>
              )}
   
      </Box>

      
    
      </Grid>
      



                    
        {footerLinks}
        </Box></>)}
    </Container></ThemeProvider>
  );
};

export default ConsentedVerified;

export {};