import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Checkbox, FormControlLabel, Link, Typography, Grid, Box, Modal, CssBaseline} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import { auth } from '../../../firebaseConfig';
import { signInWithEmailAndPassword, sendPasswordResetEmail  } from 'firebase/auth';
import { useFormik } from 'formik';
import { Analytics } from "@vercel/analytics/react"
import { footerLinks } from '../Shared/footerLinks.jsx';
import { style, config, StyledRadio, CustomListItem } from '../../../config/settings.jsx';
import { getTheme } from '../../../config/theme';



const SecurityVerification = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState('');
    const [forgotMessage, setForgotMessage] = useState('');
    const [selectedClubId, setSelectedClubId] = useState<string | null>(null);
    const [parentClubId, setParentClubId] = useState<string | null>(null);
    const navigate = useNavigate();
    const { variable } = useParams<{ variable: string }>();



    const formik = useFormik({
      initialValues: {
        email: '',
      },
         onSubmit: async (values) => {
              
          setIsLoadingForgotPassword(true);
        try {
      
          await sendPasswordResetEmail(auth, values.email);
          setOpen(false);
         
          } catch (error) {
          console.error('Error resetting email:', error);
          setForgotMessage('We experienced an error, contact support');
        }
      },
    });

    const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const signIn = await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem('userId', signIn.user.uid)
      navigate('/confirmed'); // Navigate to the clubs page upon successful login
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
      setMessage('Failed to log in. Please check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };

  const findClub = async (clubCode: string) => {
    try {
      const response = await axios.post(
        'https://minerva.sipora.io/api/clubs/find',
        { club: clubCode }, 
        { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
          tenant: config.tenant
         } }
      );
      const clubId = response.data.clubId;
      const status = response.status;
      if (status === 200) {
      setSelectedClubId(clubId);
      }
    } catch (error) {
      console.error('Error fetching clubs:', error);
    }
  };
  
  // useEffect Hook
  useEffect(() => {
    if (variable) {
      const clubCode = variable;
      findClub(clubCode);
    }
  }, [variable]); // Add the dependency array with 'variable'
  



  

  return (
    
    <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" mt={8} p={2} >
      <Grid container justifyContent="center" mb={2}>
    
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center">
    <Typography variant="h4" component="h2" align="center" mt={2} sx={{fontWeight: 'bold'}} gutterBottom>

  Confirm Account</Typography>
  </Box></Grid>
  <Grid item xs={12} justifyContent="center">
  <Box display="flex" justifyContent="center">
  <Typography variant="body2">For security purposes we require you to confirm your account</Typography>
   </Box></Grid></Grid>
          
       
     
        <form noValidate onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}
            
          />
          <TextField
            variant="outlined"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}
            InputProps={{
              endAdornment: (
                <ThemeProvider theme={getTheme}>  <Button onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </Button></ThemeProvider>
              ),
            }}
          />

          
           <ThemeProvider theme={getTheme}>
           <Box display="flex" justifyContent="center" sx={{mt: '10px', color: 'red'}}> {message}</Box> 
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3  }}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Verify'}
         
          </Button></ThemeProvider>
          <Grid item xs={12} justifyContent="center">
          <Box display="flex" justifyContent="center" p={1}>
          <Typography variant="body2"> Don't have an account? <Link component={RouterLink} to="/clubs" variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Register
            </Link>
            </Typography></Box>
            </Grid>
             <Grid item xs={12} justifyContent="center">
            <Box display="flex" justifyContent="center" p={1}>
            <Typography variant="body2">
                Forgot your password? 
              <Link onClick={handleOpen} variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Reset it
            </Link>
            </Typography></Box></Grid>
         
          {footerLinks}
        </form>
        </Box>

        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
          Forgot Password?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          We will send you an email with reset instructions.
          </Typography>
          <form onSubmit={formik.handleSubmit}>
          <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
           <Box display="flex" width="100%" justifyContent="space-between">
                   </Box>
          <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button onClick={handleClose}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
           Cancel
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoadingForgotPassword}
          >
            <Typography >
            {isLoadingForgotPassword ? 'Loading...' : 'Submit'}
            </Typography>
         
          </Button>
         

           </Box>
           
           </Box>

          </form>
          <Typography variant="caption" color="red" align="left" justifyContent="left" gutterBottom >{forgotMessage}</Typography>
        </Box>
        </Modal>
    </Container>
  );
};

export default SecurityVerification;

export {};