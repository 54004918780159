import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel, Container, CircularProgress } from '@mui/material';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks';
import { theme, style, config, StyledRadio } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps';
import { getTheme } from '../../../config/theme';

const FundingAccounts = () => {
  const [subdomain, setSubdomain] = useState('');
  const [isLoadingTheme, setIsLoadingTheme] = useState(false);
  const [theme, setTheme] = useState({});
  const [assetsPath, setAssetsPath] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [selectedAccountObjectId, setSelectedAccountObjectId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [stepperStep, setStepperStep] = useState(2);
  const { accounts } = location.state || {};
  const cdrid = localStorage.getItem('cdrid');

{/* Host name identification and configassets loading */}
useEffect(() => {
  setIsLoadingTheme(true);
  let subdomain;
  
  const loadConfigAndAssets = async () => {
  const hostname = window.location.hostname; 
  const parts = hostname.split('.'); 
  if (parts.length > 2 && parts[1] === 'sipora' && parts[2] === 'io') {
    subdomain = parts[0];
    setSubdomain(subdomain); 
  } else {
    subdomain = 'default';
  }
  localStorage.setItem('host', subdomain);

  const settings = await import(`../../../config/${subdomain}/settings.jsx`);
      setAssetsPath(settings.logoURL);  // Dynamic asset folder
  
  console.log(settings)
  setIsLoadingTheme(false);
  };

  loadConfigAndAssets();

}, []);


  useEffect(() => {
    if (!accounts || accounts.length === 0) {
      // Redirect to a different page or show an error message
      navigate('/previous-page'); // Adjust this to your needs
    }
  }, [accounts, navigate]);

  const handleCheckboxChange = (id, _id) => {
  setSelectedAccountId(id);
  setSelectedAccountObjectId(_id);
  };
  

  const handleSubmitFundingAccount = async () => {
    setIsLoading(true);
          
    try {
      await axios.post('https://minerva.sipora.io/api/client/round-up/user/register-funding', 
        {
        accountId: selectedAccountId, 
        account_id: selectedAccountObjectId, 
        tenantA: config.tenantA, 
        activeDebitEnabled: true, 
        cdrid: cdrid
      }, { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
            tenant: config.tenant
       } }
      
    );
      navigate('/finished'); // Adjust the navigation path as needed
    } catch (error) {
      console.error('Error submitting selected accounts:', error);
      setErrorMessage('There seems to be an error, please try again or contact support')
    } finally {
      setIsLoading(false);
    }
  };

  if (!accounts || accounts.length === 0) {
    return (
    
              <Container component="main" maxWidth="xs">
                
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2}>
          <Typography variant="h6" component="h2" align="center" mb={2}>
            No accounts available. Please go back and select accounts.
          </Typography>
          <Button
            onClick={() => navigate('/previous-page')} // Adjust this to your needs
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
          >
            <Typography>Go Back</Typography>
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <ThemeProvider theme={getTheme}>
    <Container component="main" maxWidth="xs">
    {isLoadingTheme ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>

      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2}>
        <Grid container justifyContent="center" mb={2}>
          <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%' }}>
            <Stepper activeStep={stepperStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Typography variant="h4" component="h2" align="center" mb={2} sx={{ fontWeight: 'bold' }} gutterBottom>
            Funding Account
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={1} p={2} borderRadius={4}>
            <Typography variant="body2" align="left" gutterBottom>
              The selected account will have round-up totals debited every Monday, Wednesday, and Friday.
            </Typography>
            <Typography variant="body2" align="left" gutterBottom>
              By selecting a funding account, you are agreeing to Zepto's terms and conditions which can be found <a href="https://www.zepto.com.au/ddrsa/">here</a>:
            </Typography>
            <Box p={1}>
              {accounts.map((account, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                    checked={selectedAccountId === account.id}
                      onChange={() => handleCheckboxChange(account.id, account._id)}
                    />
                  }
                  label={
                    <Box>
                      <Typography variant="body2">{account.name} {account.accountNumber}</Typography>
                    </Box>
                  }
                />
              ))}
            </Box>
          </Box>
        </Grid>
       
          <Button
            onClick={handleSubmitFundingAccount}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoading}
          >
            <Typography>
              {isLoading ? 'Loading...' : 'CONTINUE'}
            </Typography>
          </Button>
        
          { footerLinks }
      </Box></>)}
    </Container></ThemeProvider>
  );
};

export default FundingAccounts;
