import React from 'react';
import { Container, Typography, Grid, CssBaseline,  Toolbar, AppBar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DrawerMenu from '../Shared/DrawerMenu';
import { theme, style, config, StyledRadio } from '../../../config/settings.jsx';

  
 

const Support = () => {
   
 
    
return (
    <ThemeProvider theme={theme}>
    <CssBaseline>
    <Container component="primary" maxWidth="xs"> 
    <AppBar position="static" color="appbar" elevation={0}>
          <Toolbar>
            <DrawerMenu />
            <Typography variant="body" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Support
            </Typography>
          </Toolbar>
        </AppBar></Container>
        <Container component="secondary" maxWidth="xs"> 

  
        <Grid item xs={12} justifyContent="center" height={3}>
    </Grid>
  
    <iframe
        src="https://www.rounda.com.au/user-faqs.html"
        title="Support Page"
        style={{ width: '100%', height: '80vh', border: 'none' }}
      />
    

    <Grid item xs={12} justifyContent="center" height={30}>
    </Grid>
    </Container>
            </CssBaseline>
            </ThemeProvider>
       
  );

}
export default Support;

export {};