// src/components/screens/ClubList.tsx
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Grid, FormControlLabel, CssBaseline, Toolbar, AppBar, Checkbox, Modal, TextField, CircularProgress  } from '@mui/material';
import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import DrawerMenu from '../Shared/DrawerMenu';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {  style, config } from '../../../config/settings.jsx';
import { getTheme } from '../../../config/theme';
import { api } from '../../../config/apis.jsx';



  const validationSchema = yup.object({
    accountName: yup.string().required('Account name is required'),
    bsb: yup.string().matches(/^\d{6}$/, 'BSB must be 6 numbers').required('BSB is required'),
    accountNumber: yup.string().matches(/^\d{8,12}$/, 'Account number must be 8-12 numbers').required('Account number is required'),
     });

const LinkedAccounts = () => {
  const [assetsPath, setAssetsPath] = useState('');
  const [subdomain, setSubdomain] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingConsent, setIsLoadingConsent] = useState(false);
    const [isLoadingSavings, setIsLoadingSavings] = useState(false);
    const [isLoadingRoundUps, setIsLoadingRoundUps] = useState(false);
    const [isLoadingFunding, setIsLoadingFunding] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const cdrid = localStorage.getItem('cdrid');
    const userId = localStorage.getItem('userId');
    const [accounts, setAccounts] = useState([]);
    const [selectedDebitAccount, setSelectedDebitAccount] = useState('');
    const [fundingAccountSetError, setFundingAccountSetError] = useState('');
    const [savingsAccountValue, setSavingsAccountValue] = useState('');
    const [savingsAccountName, setSavingsAccountName] = useState('');
    const [savingsAccountMissing, setSavingsAccountMissing] = useState('');
    const [manageConsentLink, setManageConsentLink] = useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const hostname = localStorage.getItem('host');

    const formik = useFormik({
      initialValues: {
        accountName: '',
        bsb: '',
        accountNumber: '',
      },
      validationSchema: validationSchema,
         onSubmit: async (values) => {
         setIsLoading(true);
        try {
        
          const response = await axios.post(api.submitSavingsAccount, {cdrid, values},
            { headers: { 'Content-Type': 'application/json',
              apikey: config.apikey,
              tenant: config.tenant
             } }
          );
          
          window.location.reload();
          setOpen(false);

        }  catch (error) {
          console.error('Error registering user:', error);
          setMessage('Failed to register. Please try again.');
        }
      },
    });



 
    const handleRoundUpChange = (accountId, isChecked) => {
      setAccounts(prevAccounts =>
        prevAccounts.map(account =>
          account._id === accountId ? { ...account, activeRoundUpsEnabled: isChecked } : account
        )
      );
    };

      const handleSubmit = async () => {
        setIsLoadingRoundUps(true);
    const accountUpdates = accounts.map(account => ({
      accountId: account._id,
      activeRoundUpsEnabled: account.activeRoundUpsEnabled
    }));

    try {
      const response = await axios.post(
        api.updateRoundUpAccounts,
        { roundUpAccounts: accountUpdates },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant
          }
        }
      );
      console.log('Update results:', response.data.results);
    } catch (error) {
      console.error('Error updating round-up status:', error);
    } finally {
      setIsLoadingRoundUps(false);
    }
  };

  const handleDebitAccountChange = async (selectedAccount) => {
    setIsLoadingFunding(true)
    try {
      // Map through all accounts and set activeDebitEnabled
      const updatedAccounts = accounts.map(account => ({
        account_id: account._id,
        accountId: account.id,
        activeDebitEnabled: account._id === selectedAccount._id,
      }));
  
      // Send the updated accounts to the API
      const response = await axios.post(
        api.updateFundingAccount,
        {
          cdrid: cdrid,
          tenantA: config.tenantA,
          accounts: updatedAccounts // Send the updated accounts array
        },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant
          }
        }
      );
  
      // Update the selected debit account state
      setSelectedDebitAccount(selectedAccount._id);
    } catch (error) {
      setFundingAccountSetError('There seems to be a problem with changing your funding account. Please try again later or contact support.');
      console.error('Error updating funding account:', error);
    } finally {
      setIsLoadingFunding(false)}
  };

   
   
    const handleManageConsent = async () => {
      setIsLoadingConsent(true);
      try {
        const response = await axios.post(api.manageConsent,
        {cdrid: cdrid},
        { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
          tenant: config.tenant
         } }); // Replace with your actual API endpoint
        const consentLink = response.data.consentLink;
        setManageConsentLink(consentLink);
        window.location.href = consentLink // Adjust based on your API response structure
      } catch (error) {
        console.error('Error creating consent Link:', error);
      } finally {
        setIsLoadingConsent(false)
      }
    };


    useEffect(() => {

      const loadConfigAndAssets = async () => {
        setIsLoadingData(true);
        let subdomain;
    
        const hostname = window.location.hostname;
        const parts = hostname.split('.');
        if (parts.length > 2 && parts[1] === 'sipora' && parts[2] === 'io') {
          subdomain = parts[0];
          setSubdomain(subdomain);
        } else {
          subdomain = 'default';
        }
        localStorage.setItem('host', subdomain);
        console.log('subdomain:', subdomain)
        try {
          const settings = await import(`../../../config/${subdomain}/settings.jsx`);
          setAssetsPath(settings.logoURLDashboard);
        } catch (error) {
          console.error('Error loading theme and assets:', error);
        }
      };


      const fetchData = async () => {
        setIsLoadingData(true);
        try {
          // Fetch savings account information
          const savingsResponse = await axios.post(
            api.retrieveLinkedSavings,
            { userId: cdrid },
            {
              headers: {
                'Content-Type': 'application/json',
                apikey: config.apikey,
                tenant: config.tenant,
              },
            }
          );
    
          if (!savingsResponse) {
            setSavingsAccountMissing('no account linked');
          } else {
            const accountName = savingsResponse.data.savingsAccount.document.accountName;
            const account = savingsResponse.data.savingsAccount.document.accountNumber;
            const lastFourDigits = account.slice(-4);
            const maskedSection = account.slice(0, -4).replace(/\d/g, 'X');
            const displayAccount = maskedSection + lastFourDigits;
            setSavingsAccountValue(displayAccount);
            setSavingsAccountName(accountName);
            console.log(displayAccount);
          }
    
          // Fetch accounts information
          const accountsResponse = await axios.post(
            api.accounts,
            { userId: cdrid },
            {
              headers: {
                'Content-Type': 'application/json',
                apikey: config.apikey,
                tenant: config.tenant,
              },
            }
          );
          setAccounts(accountsResponse.data.accountsData.documents); // Adjust based on your API response structure
        } catch (error) {
          console.error('Error fetching data:', error);
          setMessage('Failed to retrieve data.');
        } finally {
          setIsLoadingData(false);
        }
      };

      const fetchAccounts = async () => {
        try {
          const response = await axios.post(api.accounts,
            {userId: cdrid},
            { headers: { 'Content-Type': 'application/json',
             apikey: config.apikey,
              tenant: config.tenant
             } }
            ); 
         setAccounts(response.data.accountsData.documents); // Adjust based on your API response structure
        } catch (error) {
          console.error('Error fetching clubs:', error);
        }
      };
    
      if (cdrid) {
        fetchData();
        fetchAccounts();
      }
    }, [cdrid]);
  

return (
    <ThemeProvider theme={getTheme}>
    <CssBaseline>          {isLoadingData ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>

    <Container component="primary" maxWidth="xs"> 
    <AppBar position="static" color="appbar" elevation={0}>
          <Toolbar>
            <DrawerMenu />
            <Typography variant="body" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Linked Accounts
            </Typography>
          </Toolbar>
        </AppBar></Container>
        <Container component="secondary" maxWidth="xs"> 

  
        <Grid item xs={12} justifyContent="center" height={3}>
    </Grid>

    <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Typography variant="body"  align="left" justifyContent="left" sx={{ flexGrow: 1, fontWeight: 'bold' }} gutterBottom >
         Round-Up Accounts  
        </Typography>
        <Typography variant="body2"  align="left" justifyContent="left" gutterBottom >
         Transactions will be tracked from the accounts selected below.  
        </Typography>
        <Box p={1}>
         {accounts.map((account, index) => (
           <FormControlLabel
             key={index}
             control={<Checkbox 
              checked={account.activeRoundUpsEnabled}
              onChange={(e) => handleRoundUpChange(account._id, e.target.checked)} />}
             label={
              <Box>
               <Typography variant="body2">{account.name}  {account.accountNumber}</Typography>
            </Box>
             }
            />
          ))}
          </Box>
          <Button 
          onClick={handleSubmit} 
          fullWidth
          variant="contained" 
          color="primary"
          sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
          disabled={isLoadingRoundUps}
          >
       <Typography >
            {isLoadingRoundUps ? 'Loading...' : 'Submit'}
            </Typography>
      </Button>
     </Box>

     <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Typography variant="body"  align="left" justifyContent="left" sx={{ flexGrow: 1, fontWeight: 'bold' }} gutterBottom >
        Funding Account (Debits)
        </Typography>
        <Typography variant="body2"  align="left" justifyContent="left" gutterBottom >
         The selected account will have round-up totals debited every from ever Monday , Wednesday, and Friday.
        </Typography>
        <Typography variant="body2"  align="left" justifyContent="left" gutterBottom >
        By selecting a funding account, you are agreeing to Zepto's terms and conditions which can be found here:
        </Typography>

        <Box p={1}>
         {accounts.map((account, index) => (
           <FormControlLabel
             key={index}
             control={
             <Checkbox
             checked={account.activeDebitEnabled}
             onChange={() => handleDebitAccountChange(account)} />}
             label={
              <Box>
               <Typography variant="body2">{account.name}  {account.accountNumber}</Typography>
            </Box>
             }
            />
          ))}
          </Box>
          <Typography variant="caption" color="red" align="left" justifyContent="left" gutterBottom >{fundingAccountSetError}</Typography>
 
     </Box>
     {hostname !== 'aca' && (
  <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={2} p={2} borderRadius={4} >
    <Typography variant="body"  align="left" justifyContent="left" sx={{ flexGrow: 1, fontWeight: 'bold' }} gutterBottom >
         Linked Savings Account  
        </Typography>
     <Box p={1}>
      {savingsAccountMissing}
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" mt='10px'>
                                  {savingsAccountName}
                  </Box>

            <Box display="flex" alignItems="center" justifyContent="center" width="100%" mt='10px'>
                                  {savingsAccountValue}
                  </Box>
          </Box>
          <Button
            onClick={handleOpen}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            >
            Change Account
         
          </Button>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
           Savings Account
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
            Link a savings account for 50% of round-ups deposited monthly
          </Typography>
          <form onSubmit={formik.handleSubmit}>
          <TextField
              fullWidth
              id="accountName"
              name="accountName"
              label="Account Name"
              value={formik.values.accountName}
              onChange={formik.handleChange}
              error={formik.touched.accountName && Boolean(formik.errors.accountName)}
              helperText={formik.touched.accountName && formik.errors.accountName}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
           <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="30%" >
            <TextField
              fullWidth
              id="bsb"
              name="bsb"
              label="BSB"
              value={formik.values.bsb}
              onChange={formik.handleChange}
              error={formik.touched.bsb && Boolean(formik.errors.bsb)}
              helperText={formik.touched.bsb && formik.errors.bsb}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
          </Box>
          <Box display="flex" width="69%" >
            <TextField
              fullWidth
              id="accountNumber"
              name="accountNumber"
              label="Account Number"
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
              helperText={formik.touched.accountNumber && formik.errors.accountNumber}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
          </Box></Box>
          <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button onClick={handleClose}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
           Cancel
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button
            type="submit"
            fullWidth
            variant="contained"
            color="switchColor"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoadingSavings}
          >
            <Typography >
            {isLoadingSavings ? 'Loading...' : 'Submit'}
            </Typography>
         
          </Button>

           </Box>
           </Box>

          </form>

        </Box>
        </Modal>
     </Box> )}
     <Button onClick={handleManageConsent}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoadingConsent}
          >
            <Typography >
            {isLoadingConsent ? 'Loading...' : 'Manage Bank Connection'}
            </Typography>
         
          </Button>
     <Grid item xs={12} justifyContent="center" height={30}>
    </Grid>

   

    </Container>
            </>)}</CssBaseline>
            </ThemeProvider>
       
  );

}
export default LinkedAccounts;

export {};