import { createTheme } from '@mui/material/styles';



// Function to generate the theme dynamically
export const getTheme = () => {
  // Fetch value from localStorage (assuming 'subdomain' is stored there)
  const subdomain = localStorage.getItem('host') || 'default';

  // Define default palette values
  let primaryColor = '#000000';
  let backgroundColor = '#47b3b5';
  let switchColor = '#47b3b5';
  let primaryBackground = '#ffffff';
  let secondaryBackground = '#47b3b5';
  
  // Check for subdomain or other condition to dynamically set theme values
  if (subdomain === 'cmi') {
    primaryColor = '#197097';  // Example: change primary color for 'special' subdomain
    backgroundColor = '#2aaadd';  // Example: change background color
    switchColor = '#2aaadd'; 
    primaryBackground = '#ffffff';
    secondaryBackground = '#2aaadd'; // Example: change switch color
  };

  if (subdomain === 'ecoforce') {
    primaryColor = '#46499e';  // Example: change primary color for 'special' subdomain
    backgroundColor = '#46499e';  // Example: change background color
    switchColor = '#46499e'; 
    primaryBackground = '#ffffff';
    secondaryBackground = '#46499e'; // Example: change switch color
  };

  if (subdomain === 'lifechanger') {
    primaryColor = '#000000';  // Example: change primary color for 'special' subdomain
    backgroundColor = '#bcbdc1';  // Example: change background color
    switchColor = '#fdd10c'; 
    primaryBackground = '#ffffff';
    secondaryBackground = '#bcbdc1'; // Example: change switch color
  };

  if (subdomain === 'familyofleague') {
    primaryColor = '#000000';  // Example: change primary color for 'special' subdomain
    backgroundColor = '#47b3b5';  // Example: change background color
    switchColor = '#47b3b5'; 
    primaryBackground = '#ffffff';
    secondaryBackground = '#47b3b5'; // Example: change switch color
  };

  if (subdomain === 'aca') {
    primaryColor = '#383478';  // Example: change primary color for 'special' subdomain
    backgroundColor = '#383478';  // Example: change background color
    switchColor = '#ffbb00'; 
    primaryBackground = '#ffffff';
    secondaryBackground = '#383478'; // Example: change switch color
  };

  if (subdomain === 'tsb') {
    primaryColor = '#2e25ee';  // Example: change primary color for 'special' subdomain
    backgroundColor = '#2e25ee';  // Example: change background color
    switchColor = '#048f4e'; 
    primaryBackground = '#ffffff';
    secondaryBackground = '#2e25ee'; // Example: change switch color
  };

  if (subdomain === 'ss4h') {
    primaryColor = '#e83c8d';  // Example: change primary color for 'special' subdomain
    backgroundColor = '#e83c8d';  // Example: change background color
    switchColor = '#e83c8d'; 
    primaryBackground = '#ffffff';
    secondaryBackground = '#e83c8d'; // Example: change switch color
  };

  if (subdomain === 'demo') {
    primaryColor = '#a30046';  // Example: change primary color for 'special' subdomain
    backgroundColor = '#a30046';  // Example: change background color
    switchColor = '#0055a3'; 
    primaryBackground = '#ffffff';
    secondaryBackground = '#a30046'; // Example: change switch color
  };





  // Define and return the theme object
  return createTheme({
    palette: {
      primary: {
        main: primaryColor,  // Dynamically set primary color
      },
      background: {
        default: backgroundColor,  // Dynamically set background color
      },
      switchColor: {
        main: switchColor,  // Dynamically set switch color
      },
      appbar: {
        default: '#ffffff',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: primaryBackground,
          },
          secondary: {
            backgroundColor: secondaryBackground, // Ensure the body has the same background colour
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: switchColor,
            },
            '&.Mui-completed': {
              color: switchColor,
            },
          },
        },
      },
    },
  });
};
