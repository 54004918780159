import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Divider, Skeleton, CircularProgress} from '@mui/material';
import axios from 'axios';
import { api } from '../../config/apis.jsx';
import { config } from '../../config/settings.jsx';
import { useNavigate } from 'react-router-dom';

const DonatedBox = ({ assetsPath, cdrid, clubId }) => {
  const [supportedClub, setSupportedClub] = useState('');
  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  // useEffect to fetch data when cdrid or clubId changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (cdrid) {
          await getTotalDebits();
        }
        if (clubId) {
          await getClubName();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [cdrid, clubId]);

  // Function to get total debits
  const getTotalDebits = async () => {
    try {
      const response = await axios.post(
        api.retrieveTotalDebits,
        { cdrid },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant,
          },
        }
      );
      setTotalDebitAmount(response.data.totalDebitAmount);
    } catch (error) {
      console.error('Failed to retrieve total payments', error);
    }
  };

  // Function to get club name
  const getClubName = async () => {
    try {
      const response = await axios.get(api.retrieveClubs, {
        headers: {
          'Content-Type': 'application/json',
          apikey: config.apikey,
          tenant: config.tenant,
        },
      });
      const club = response.data.clubs.find((club) => club._id === clubId);
      if (club) {
        setSupportedClub(club.name);
      }
    } catch (error) {
      setMessage('Failed to find Clubs. Please try again.');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      bgcolor="white"
      mt={2}
      p={2}
      borderRadius={4}
    >
      {isLoading ? (
        <CircularProgress
        />
      ) : (
        <>
          <Grid container justifyContent="center" mb={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <img
                  src={assetsPath}
                  alt="Logo"
                  style={{ width: '60%', height: 'auto' }}
                />
              </Box>
            </Grid>
          </Grid>
          <Typography
            variant="body"
            align="center"
            sx={{ fontWeight: 'bold' }}
            gutterBottom
          >
            Supporting:
          </Typography>
          <Typography variant="h6" align="center" gutterBottom>
            {supportedClub}
          </Typography>
          <Box display="flex" alignItems="center" width="100%">
            <Box flexGrow={1}>
              <Divider />
            </Box>
            <Typography
              variant="body"
              sx={{ fontWeight: 'bold', mx: 2 }}
              gutterBottom
            >
              Donated
            </Typography>
            <Box flexGrow={1}>
              <Divider />
            </Box>
          </Box>
          <Typography variant="h5" align="center" gutterBottom>
            ${((totalDebitAmount / 100).toFixed(2))}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default DonatedBox;
