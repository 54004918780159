import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Checkbox, FormControlLabel, Link, Typography, Grid, Box, Modal, CssBaseline} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import { auth } from '../../../firebaseConfig.js';
import { signInWithEmailAndPassword, sendPasswordResetEmail  } from 'firebase/auth';
import { useFormik } from 'formik';
import { Analytics } from "@vercel/analytics/react"
import { footerLinks } from '../Shared/footerLinks.jsx';
import { style, config, StyledRadio, CustomListItem } from '../../../config/settings.jsx';
import { getTheme } from '../../../config/theme.jsx';



const ExtendConsent = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState('');
    const [forgotMessage, setForgotMessage] = useState('');
    const [selectedClubId, setSelectedClubId] = useState<string | null>(null);
    const [parentClubId, setParentClubId] = useState<string | null>(null);
    const navigate = useNavigate();
    const { email } = useParams<{ email: string }>();
    const [extendLink, setExtendLink] = useState('');
    const [linkLoaded, setLinkLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
 


  const getExtendLink = async () => {
    try {
      const response = await axios.post(
        'https://minerva.sipora.io/api/client/round-up/user/consent-extend',
        { email: email }, 
        { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
          tenant: config.tenant
         } }
      );
     
      if (response.data.consentLink) {
      setExtendLink(response.data.consentLink);
      setLinkLoaded(true);
      }
    } catch (error) {
      console.error('Error fetching clubs:', error);
      setErrorMessage('We have encountered an issue, please contact info@rounda.com.au')
    }
  };
  
  // useEffect Hook
  useEffect(() => {
    if (email) {
     getExtendLink();
    }
  }, []); // Add the dependency array with 'variable'
  

  const handleExtendConsentLink = async () => {
    window.location.href = extendLink;
  
  }

  

  return (
    
    <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" mt={8} p={2} >
      <Grid container justifyContent="center" mb={2}>
    
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center">
    <Typography variant="h4" component="h2" align="center" mt={2} sx={{fontWeight: 'bold'}} gutterBottom>

  Extend CDR Consent</Typography>
  </Box></Grid>
  <Grid item xs={12} justifyContent="center">
  <Box display="flex" justifyContent="left" marginBottom={2} marginTop={2}>
  <Typography variant="body2">Hello</Typography>
   </Box>
  <Box display="flex" justifyContent="center">
  <Typography variant="body2">Consumer Data Right guidelines ask accredited providers to get your consent every 12 months to round-up change for your organisation.</Typography>
   </Box></Grid></Grid>
          
 
    
     

          
           <ThemeProvider theme={getTheme}>
           <Box display="flex" justifyContent="center" sx={{mt: '10px', color: 'red'}}>    {errorMessage}</Box> 
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3  }}
            disabled={!linkLoaded}
            onClick={handleExtendConsentLink}
          >
            {isLoading ? 'Loading...' : 'Extend'}
         
          </Button></ThemeProvider>
          <Grid item xs={12} justifyContent="center">
          <Box display="flex" justifyContent="center" p={1}>
          <Typography variant="body2"> Don't have an account? <Link component={RouterLink} to="/clubs" variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Register
            </Link>
            </Typography></Box>
            </Grid>
             <Grid item xs={12} justifyContent="center">
            <Box display="flex" justifyContent="center" p={1}>
            <Typography variant="body2">
                Forgot your password? 
              <Link onClick={handleOpen} variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Reset it
            </Link>
            </Typography></Box></Grid>
         
  
        </Box>

    </Container>
  );
};

export default ExtendConsent;

export {};