import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyD4RD0U4S8QaOpe46ETGqs3TZ5lPIQzprM",
  authDomain: "rounda-stage.firebaseapp.com",
  projectId: "rounda-stage",
  storageBucket: "rounda-stage.appspot.com",
  messagingSenderId: "155641943116",
  appId: "1:155641943116:web:01d6d6b6c052fe9db990e9",
  measurementId: "G-4Q6V1NXL0R"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.useDeviceLanguage();

const analytics = getAnalytics(app);

export { auth, analytics };