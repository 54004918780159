import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Container, Typography, Box, Button, Grid, Stepper, Step, StepLabel, CircularProgress } from '@mui/material';
import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks';
import { theme, style, config,  } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps';
import { api } from '../../../config/apis.jsx';
import { getTheme } from '../../../config/theme';

const RoundupAccounts = () => {
  const [subdomain, setSubdomain] = useState('');
  const [isLoadingTheme, setIsLoadingTheme] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [theme, setTheme] = useState({});
  const [assetsPath, setAssetsPath] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState('Checking your bank connection and authorising accounts');
  const [pageTitle, setPageTitle] = useState('Connecting...');
  const location = useLocation();
  const cdrid = localStorage.getItem('cdrid');
  const [stepperStep, setStepperStep] = useState(1);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

{/* Host name identification and configassets loading */}
useEffect(() => {
  setIsLoadingTheme(true);
  let subdomain;
  
  const loadConfigAndAssets = async () => {
  const hostname = window.location.hostname; 
  const parts = hostname.split('.'); 
  if (parts.length > 2 && parts[1] === 'sipora' && parts[2] === 'io') {
    subdomain = parts[0];
    setSubdomain(subdomain); 
  } else {
    subdomain = 'default';
  }
  localStorage.setItem('host', subdomain);

  const settings = await import(`../../../config/${subdomain}/settings.jsx`);
      setTheme(settings.theme);  // Assuming default export
      setAssetsPath(settings.logoURL);  
  console.log(settings)
  setIsLoadingTheme(false);
  };

  loadConfigAndAssets();

}, []);


  useEffect(() => {
     fetchAccounts();
  }, []);

    const fetchAccounts = async () => {
      setIsLoadingAccounts(true);
    try {
      const response = await axios.post(api.accounts,
        {userId: cdrid},
        { headers: { 'Content-Type': 'application/json',
         apikey: config.apikey,
            tenant: config.tenant
         } }
        ); 
        console.log(response.data.accountsData);
     setAccounts(response.data.accountsData.documents);
     setStepperStep(2); // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching clubs:', error);
    } finally {
      setIsLoadingAccounts(false);

    }
  };

  const handleCheckboxChange = (event, accountId) => {
    if (event.target.checked) {
      setSelectedAccounts([...selectedAccounts, accountId]);
    } else {
      setSelectedAccounts(selectedAccounts.filter(id => id !== accountId));
    }
  };

  const handleSubmitRoundUpAccounts = async () => {
    const RoundUpAccounts = accounts.map(account => ({
      accountId: account._id,
      activeRoundUpsEnabled: selectedAccounts.includes(account.id),
    }));

  try {
    await axios.post(api.setRoundUpAccounts, {
      RoundUpAccounts,
    },
    { headers: { 'Content-Type': 'application/json',
      apikey: config.apikey,
      tenant: config.tenant
     } });
     
    navigate('/funding-accounts', { state: { accounts } });
  } catch (error) {
    console.error('Error submitting selected accounts:', error);
  }
};
  

  return (
    <ThemeProvider theme={getTheme}>
    <Container component="main" maxWidth="xs">
    {isLoadingTheme || isLoadingAccounts ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>

    <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
    <Grid container justifyContent="center" mb={2}>

  
         <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
      <Stepper activeStep={1} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
      </Box>
    
      <Typography variant="h4" component="h2" align="center" mb={2} sx={{fontWeight: 'bold'}} gutterBottom>
      Setting Up
    </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={1} p={2} borderRadius={4} >
    
        <Typography variant="body2"  align="left" justifyContent="left" gutterBottom >
         Transactions will be tracked from the accounts selected below.  
        </Typography>
        <Box p={1}>
         {accounts.map((account, index) => (
           <FormControlLabel
             key={index}
             control={
             <Checkbox
             onChange={(event) => handleCheckboxChange(event, account.id)}
              />}
             label={
              <Box>
               <Typography variant="body2">{account.name}  {account.accountNumber}</Typography>
            </Box>
             }
            />
          ))}
          </Box>
     </Box>

     
    
      </Grid>
    
   
          <Button
            onClick={handleSubmitRoundUpAccounts}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
                  
          >
          <Typography>
    {isLoading ? 'Loading...' : 'Next'}
  </Typography>
         
          </Button>



                    
          { footerLinks }
        </Box></>)}
    </Container></ThemeProvider>
  );
};

export default RoundupAccounts;

export {};